<template>
  <div id="course-create-details-tab">
    <div class="vx-col w-full">
      <json-viewer theme="dark-json-theme" class="rounded-lg" :value="dataLocal"></json-viewer>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import JsonViewer from 'vue-json-viewer';
import 'vue-json-viewer/style.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
    };
  },
  components: {
    vSelect,
    JsonViewer,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.dark-json-theme {
  background: rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  color: #ffffff;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #ffffff;
  }
  .jv-item {
    &.jv-array {
      color: #ffffff;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-number-float {
      color: #fc1e70;
    }
    &.jv-number-integer {
      color: #fc1e70;
    }
    &.jv-object {
      color: #ffffff;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
</style>
